<template>
  <div class="rounded-lg shadow-lg">
    <form-component mode="create" :user="user" />
  </div>
</template>
<script>
import formComponent from "./components/formComponent.vue";
export default {
  components: {
    formComponent,
  },
  data() {
    return {
      user: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        status: "",
      },
    };
  },
};
</script>
